<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Spring Mass System</span>
      </div>
      <div ref="ia" style="margin-top: 20px" />
      A spring-mass system is a mechanical system composed of a mass (or several masses) connected to a spring.The spring is used to provide a restoring force that acts on the mass when it is displaced from its equilibrium position.Spring-mass systems are commonly used in many engineering applications, such as suspension systems in vehicles, shock absorbers, and vibration isolators.
      <div ref="ib" style="margin-top: 20px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Oscillations of Spring Mass System</span>
      </div>
      <div ref="ib" style="margin-top: 20px" />
      From the definition of Simple Harmonic Motion, the force acting on the body executing it is:
      $$ F=-kx $$
      where \(k\) is the spring constant of the spring.
      Using Newton's second law, force can be expressed in terms of mass \(m\) and acceleration \(a\).
      $$F = m a = m \frac{d^2x}{dt^2}$$
      <br>
      Substituting this in original equation, we obtain &mdash;
      $$\frac{d^2 x}{dt^2}=-\frac{k}{m}x $$
      This is the governing different equation for oscillations of a spring-mass system.
      The general solution for the above equation is of the form:
      $$x= A \sin(\omega t + \phi)$$
      Here \(A\) is the amplitude of the oscillations, \(\phi\) is called the phase constant, and \(\omega\) is called the angular frequency of the oscillations.
      <br> Given the position, we can easily obtain the other parameters like velocity and acceleration. Taking the first derivative gives &mdash;
      $$\frac{dx}{dt}=A\omega \cos(\omega t+\phi)$$
      Taking the second derivative gives &mdash;
      $$\frac{d^2x}{dt^2}=-A\omega^2 \sin(\omega t+\phi)$$
      Substituting this in the governing differential equation, we obtain &mdash;
      $$ - A\omega^2 \sin(\omega t+\phi) = - \frac{k}{m} A \sin(\omega t+\phi)$$
      Upon simplifying we obtain &mdash;
      $$\omega^2 = \frac{k}{m}$$
      Or, equivalently
      $$ \omega = \sqrt{\frac{k}{m}}$$
      <div ref="ib" style="margin-top: 20px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Frequency & Time Period of Oscillations</span>
      </div>
      <div ref="ic" style="margin-top: 20px" />
      The time period of simple harmonic motion is related to angular frequency as:
      $$T=\frac{2\pi}{\omega}$$
      Substituting for \(\omega\) we obtain &mdash;
      $$T = 2\pi \sqrt{\frac{m}{k}}$$
      <div ref="id" style="margin-top: 20px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Learn Interactively | Spring-Mass System</span>
      </div>
      <div ref="ie" style="margin-top: 20px" />
      You are shown a spring-mass system. Select mass of the block and spring constant. Drag the mass down and then let go to see the oscillations of the block.
    </div>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5 class="edliy-text-about">
          Select mass of the block and spring constant. Drag the mass down and then let go to see the oscillations of the block.
        </h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Resistors',
  created: function () {
    // Store mutations
    let title = 'Spring-Mass System';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Spring-mass system', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Oscillations', img:'/assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'Interactive Session',img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Spring Mass System',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Balancing a chemical equation.'}
                ]
        }
   }
}
</script>
