import {
  makeResponsive,
  //makeResponsive(brd1);
  placeTitle,
  //placeTitle(brd1,'title', 'subtitle');
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  //placeArrow(board, pt1, pt2, width, cols)
  placeGravity,
  placeText,
  //placeText(board, positionX, positionY, message)
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeSliderSwitch,
  placeBoldText,
  //placeBoldText(brd6, -9, 1, 'Liquid');
  placeDash,
  // placeDash(board, [x1, y1], [x2, y2], width, cols)
  writeRightText,
  writeLeftText,
  placeLabel,
  placeLogo,
  placeShuffle,
  toggle,
  writeHTMLText,
  alert,
  writeMiddleText,
  placeStartOver,
  drawSegment,
  drawPoint
} from '../Utils';
const Boxes = {
  box1: function () {
    var board = JXG.JSXGraph.initBoard('jxgbox1', {boundingbox: [-6, 10, 9, -5], keepaspectratio: true, axis:false, grid:true, ticks:false, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
    //
    board.options.board.minimizeReflow = 'none';
    board.options.text.highlight=false;
    board.options.text.fixed=true;
    board.options.image.highlight=false;
    board.options.text.cssDefaultStyle='fontFamily:Oswald';
    board.options.line.highlight=false;
    board.options.curve.highlight=false;
    board.options.point.highlight=false;
    board.options.point.showInfoBox=false;
    //
    board.options.layer['image'] =10;
    //
    makeResponsive(board);
    placeLogo(board);
    placeTitle(board, 'Spring Mass System', '(Drag the mass down. Then let go)');
    //
    let xAxis =board.create('axis', [[0, 0],[5, 0]], { strokeColor:'grey',
      highlight:false, strokeWidth: ()=>Math.round(2*board.canvasWidth/800.),
      withLabel:true, name: '', straightFirst:false, straightLast:true, lastArrow:false,
        label: {
          display:'internal',
          fontSize:function(){return 24*board.canvasHeight/800},
          CssStyle: 'fontFamily:Oswald;fontWeight:bold',
          position: 'rt',
          offset: [-20, 20]}});

  //
  let yAxis =board.create('axis', [[0, -5],[0, 8]], { strokeColor:'grey',
          highlight:false, strokeWidth: ()=>Math.round(2*board.canvasWidth/800.),
          withLabel:true, name: '', straightFirst:false, straightLast:false, lastArrow:false,
            label: {
              display:'internal',
              fontSize:function(){return 24*board.canvasHeight/800},
              CssStyle: 'fontFamily:Oswald;fontWeight:bold',
              position: 'rt',
              offset: [-20, 20]}});
    var line = board.create('line', [[-2.5,8], [-2.5, -8]], {visible: false, straightFirst: false, straightLast: false});
    //
    var point = board.create('glider', [-2.5, 0, line], {name: '', size:function(){return 45*board.canvasHeight/800}, face:'square'});
    //
    var ktext= board.create('text',[-1.5, function(){return (5+point.Y())/2.0}, 'k'],{display:'internal', cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*board.canvasWidth/500.)}, fixed:true});
    //
    var mtext= board.create('text',[-2.5, function(){return point.Y()}, 'm'],{display:'internal', anchorX:'middle', cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*board.canvasWidth/500.)}, fixed:true});
    //
    var Ltext= board.create('text',[-5, 2.5, 'L_o'],{display:'html', anchorX:'middle', cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*board.canvasWidth/500.)}, fixed:true});
    //
    var msg1 =writeMiddleText(board, 4.5, 6.5, 'Max weight reached!');
    msg1.setAttribute({color:'red', visible:false});
    //
    var msg2 =writeMiddleText(board, 4.5, 6.5, 'Min weight reached!');
    msg2.setAttribute({color:'red', visible:false});
    //
    var m=0.25;
    var add = board.create('image', ['/assets/plus.svg', [-5., 7-0.375], [0.75, 0.75]],{fixed:true});
    var remove = board.create('image', ['/assets/minuss.svg', [-1.75, 7-0.375], [0.75, 0.75]],{fixed:true});
    //
    add.on('down',function(){if(m<4*0.25){m+=1*0.25}else{m=4*0.25; alert(board, msg1)}; point.moveTo([-2.5, 0.0]);board.stopAllAnimation(); Pt0.moveTo([0, ()=>point.Y()]); vis=false, viss=false});
    remove.on('down',function(){if(m>1*0.25){m-=1*0.25;}else{m=1*0.25; alert(board, msg2)}; point.moveTo([-2.5, 0.0]);board.stopAllAnimation(); Pt0.moveTo([0, ()=>point.Y()]); vis=false, viss=false});
    //
    var k=1;
    var addK = board.create('image', ['/assets/plus.svg', [-5., 6-0.375], [0.75, 0.75]],{fixed:true});
    var removeK = board.create('image', ['/assets/minuss.svg', [-1.75,6-0.375], [0.75, 0.75]],{fixed:true});
    addK.on('down',function(){if(k<4){k+=1}else{k=4; alert(board, msg1)};point.moveTo([-2.5, 0.0]);board.stopAllAnimation(); Pt0.moveTo([0, ()=>point.Y()]); vis=false, viss=false});
    removeK.on('down',function(){if(k>1){k-=1;}else{k=1; alert(board, msg2)}; point.moveTo([-2.5, 0.0]);board.stopAllAnimation(); Pt0.moveTo([0, ()=>point.Y()]); vis=false, viss=false});
    //
    board.create('text',[-3., 7, ()=>'m = '+m.valueOf()+' kg'],{display:'internal', anchorX:'middle', color:'red', cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*board.canvasWidth/500.)}, fixed:true});
//
    board.create('text',[-3., 6, ()=>'k = '+k.valueOf()+' N/m'],{display:'internal', anchorX:'middle', color:'blue', cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*board.canvasWidth/500.)}, fixed:true});
    //
    var isInDragMode = false;
    //
    var springHangup = board.create('point', [0, 0], {color: 'black', name: '', fixed: true, visible:false});
    //
    var i=0;
    var numberOfSpringRings = 50;
    var springRings = [];
    //
    for(i=0; i<numberOfSpringRings; i++) {
        springRings[i] = board.create('point', [-2.5+0.7*(i%2-0.5), function(i){return function(){return 5-((5-point.Y())/numberOfSpringRings)*i};}(i)], {withLabel:false, visible:false, color: 'black', size: 1});
          if(i>0)
            board.create('segment', [springRings[i-1], springRings[i]], {color:'blue', strokeWidth:()=>2*board.canvasWidth/800});
          };
    //
    //
    var vbase=board.create('polygon',[[-6,5],[-0.,5],[-0.,8],[-6, 8]], {fillColor:'lightgrey', fillOpacity:0.6, withLines:false, vertices:{visible:false}});
    //  board.create('segment', [springHangup, springRings[0]], {color: 'black', strokeWidth: 1});
    board.create('point', [-4.5, 0.1], {name: '', color:'grey', size:function(){return 4*board.canvasHeight/800}, face:'triangledown'});
//
    board.create('point', [-4.5, 4.9], {name: '', color:'grey', size:function(){return 4*board.canvasHeight/800}, face:'triangleup', fixed:true});
    //
    board.create('segment', [[-4.5, 0],[-4.5, 5] ], {fixed:true, strokeColor:'black', strokeWidth:1});
    //
    function startAnimation(startY) {
    point.moveAlong(function(){
    let dt = 1000;
    let duration = 10*dt*2*Math.PI*m.valueOf()/k.valueOf();
    return function(t) {
        if (t >= duration){
            //a=0;
            return [0, 0];
          }
        else {
        a= startY;
        return [-2.5, startY*Math.cos((k.valueOf()/m.valueOf())*t/dt)]
      }
    }}()
  );}
  var a =0; var vis=false;
  var Pt0 = board.create('point', [0, 0], {name: '', color:'black',visible:()=>vis==true||viss==true, size:function(){return 5*board.canvasHeight/800}, face:'triangleleft'});
  //
  function startPlotting(startY) {
  Pt0.moveAlong(function(){
  let dt = 1000;
  let duration = 10*dt*2*Math.PI*m.valueOf()/k.valueOf();
  let half = 9*dt*Math.sqrt(m.valueOf()/k.valueOf());
  let secondhalf= 18*dt*Math.sqrt(m.valueOf()/k.valueOf());
  return function(t) {
      if (t >= duration){
          viss=true;
        //  a=0;
        //  return [0, 0];
        }
      else if (t>=half){
        viss=true;
        vis = false
        return [t/dt/(Math.sqrt(m.valueOf()/k.valueOf()))-9, startY*Math.cos((k.valueOf()/m.valueOf())*t/dt)]
      }
      else {
      a= startY;
      vis=true;
      viss=false;
      return [t/dt/(Math.sqrt(m.valueOf()/k.valueOf())), startY*Math.cos((k.valueOf()/m.valueOf())*t/dt)]
    }
  }}()
);}
//
  let Pt1 = board.create('point', [0+0.1, ()=>-1.25*Math.abs(a)], {name: '', color:'black',visible:()=>vis==true||viss==true, size:function(){return 4*board.canvasHeight/800}, face:'triangleleft'});
//
  board.create('text',[()=>Math.PI*2*Math.sqrt(m.valueOf()/k.valueOf())/2, ()=>-1.4*Math.abs(a), ()=>'T = '+Math.min(Pt0.X(), Math.PI*2*Math.sqrt(m.valueOf()/k.valueOf())).toFixed(2)+' sec'],{visible:()=>vis==true||viss==true, display:'internal',
  anchorY:'top', anchorX:'middle', cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*board.canvasWidth/500.)}, fixed:true});
//
  let Pt2 =board.create('point', [()=>Math.min(Pt0.X(), Math.PI*2*Math.sqrt(m.valueOf()/k.valueOf())), ()=>-1.25*Math.abs(a)], {name: '', visible:()=>vis==true||viss==true, color:'black', size:function(){return 4*board.canvasHeight/800}, face:'triangleright', fixed:true});
//
  var viss=false;
  var plot = board.create('functiongraph', [function(x){return a*Math.cos(Math.sqrt(k.valueOf()/m.valueOf())*x)}, 0, ()=>Pt0.X()],{visible:()=>vis==true,strokeColor:'grey', strokeWidth:()=>3*board.canvasWidth/800});
  board.create('functiongraph', [function(x){return a*Math.cos(Math.sqrt(k.valueOf()/m.valueOf())*(x+9))}, 0, ()=>Pt0.X()],{visible:()=>viss==true,strokeColor:'grey', strokeWidth:()=>3*board.canvasWidth/800});
//
  var Pt3=drawPoint(board, -3.5, 0).setAttribute({visible:false});
  var Pt4=drawPoint(board, -5.5, 0).setAttribute({visible:false});
//
  drawSegment(board, Pt3, Pt4).setAttribute({strokeColor:'grey'});
//
  drawSegment(board, Pt1, Pt2).setAttribute({visible:()=>vis==true||viss==true});
//
//  add.on('down', function(){return board.stopAllAnimation();point.moveTo([-2.5,0])});
//  k.on('down', function(){return board.stopAllAnimation(); point.moveTo([-2.5, 0])});
    function hook() {
      if(!isInDragMode) {
        if(board.mode === board.BOARD_MODE_DRAG) {
        isInDragMode = true;
        board.stopAllAnimation(); }
      }
    if(isInDragMode) {
    if(board.mode !== board.BOARD_MODE_DRAG) {
        isInDragMode = false;
        startAnimation(point.Y());
        startPlotting(point.Y());
    }
    }
    }
    board.addHook(hook);
},
}
export default Boxes;
